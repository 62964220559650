// export const MOCK_COURSES = [
//   {
//     categories: [{ id: 1, title: "Abs" }],
//     duration: 1200000,
//     highlight: "featured",
//     id: "1",
//     instructor: "Trainer/Instructor",
//     level: 3,
//     file_poster: "https://i.vimeocdn.com/video/693773775_1280x720.jpg",
//     rating_stars: 5,
//     rating_votes: 1234,
//     title: "Standing Abs 2",
//   },
//   {
//     categories: [{ id: 1, title: "Cardio" }],
//     duration: 1560000,
//     highlight: null,
//     id: "2",
//     instructor: "Trainer/Instructor",
//     level: 4,
//     file_poster: "https://i.vimeocdn.com/video/851540766_1920x1080.jpg",
//     rating_stars: 4,
//     rating_votes: 1234,
//     title: "The Leg Crank",
//   },
//   {
//     categories: [{ id: 1, title: "Yoga" }],
//     duration: 1320000,
//     highlight: "staff-pick",
//     id: "3",
//     instructor: "Trainer/Instructor",
//     level: 4,
//     file_poster:
//       "https://go.burnalong.com/sites/default/files/videos/thumbnails/morning_khepera.jpg",
//     rating_stars: 4,
//     rating_votes: 1234,
//     title: "Morning Wake Up",
//   },
//   {
//     categories: [{ id: 1, title: "Abs" }],
//     duration: 900000,
//     highlight: "promoted",
//     id: "4",
//     instructor: "Trainer/Instructor",
//     level: 3,
//     file_poster:
//       "https://i.vimeocdn.com/video/586334106_1280x720.jpghttps://i.vimeocdn.com/video/586334106_1280x720.jpg",
//     rating_stars: 3,
//     rating_votes: 1234,
//     title: "Standing Abs",
//   },
//   {
//     categories: [{ id: 1, title: "Educational" }],
//     duration: 300000,
//     highlight: "featured",
//     id: "5",
//     instructor: "Trainer/Instructor",
//     level: 1,
//     file_poster: "https://i.vimeocdn.com/video/677843380_1280x720.jpg",
//     rating_stars: 1,
//     rating_votes: 1234,
//     title:
//       "Fawn Heart Fit Nutrition Tip: ACV, The Perfect Way to Start the Morning",
//   },
//   {
//     categories: [{ id: 1, title: "Bodyweight" }],
//     duration: 33,
//     highlight: "featured",
//     id: "6",
//     instructor: "Trainer/Instructor",
//     level: 2,
//     file_poster: "https://i.vimeocdn.com/video/927775902_1920x1080.jpg",
//     rating_stars: 2,
//     rating_votes: 1234,
//     title: "30 minute Bodyweight Strength Workout #3",
//   },
//   {
//     categories: [{ id: 1, title: "Abs" }],
//     duration: 900000,
//     highlight: null,
//     id: "7",
//     instructor: "Trainer/Instructor",
//     level: 3,
//     file_poster: "https://i.vimeocdn.com/video/748756125_1280x720.jpg",
//     rating_stars: 3,
//     rating_votes: 1234,
//     title: "Muffin Blast",
//   },
//   {
//     categories: [{ id: 1, title: "Yoga" }],
//     duration: 2160000,
//     highlight: null,
//     id: "8",
//     instructor: "Trainer/Instructor",
//     level: 1,
//     file_poster: "https://i.vimeocdn.com/video/916477524_1920x1080.jpg",
//     rating_stars: 1,
//     rating_votes: 1234,
//     title: "Yoga for Anxiety",
//   },
//   {
//     categories: [{ id: 1, title: "Strength" }],
//     duration: 300000,
//     highlight: "staff-pick",
//     id: "9",
//     instructor: "Trainer/Instructor",
//     level: 2,
//     file_poster:
//       "https://go.burnalong.com/sites/default/files/videos/thumbnails/uflegcrusher.jpg",
//     rating_stars: 2,
//     rating_votes: 1234,
//     title: "Leg Crusher",
//   },
//   {
//     categories: [{ id: 1, title: "Barre" }],
//     duration: 900000,
//     highlight: "promoted",
//     id: "10",
//     instructor: "Trainer/Instructor",
//     level: 3,
//     file_poster:
//       "https://go.burnalong.com/sites/default/files/videos/thumbnails/mcd_back_butt_and_core_challenge.jpg",
//     rating_stars: 3,
//     rating_votes: 1234,
//     title: "Arms, Back, Butt, and Core Challenge",
//   },
// ];

// export const MOCK_LIVE_COURSES = [
//   {
//     categories: [{ id: 1, title: "Abs" }],
//     duration: 4320000,
//     highlight: "featured",
//     id: "1",
//     instructor: "Trainer/Instructor",
//     level: 3,
//     file_poster: "https://i.vimeocdn.com/video/693773775_1280x720.jpg",
//     rating_stars: 3,
//     rating_votes: 1234,
//     title: "Standing Abs 2",
//   },
//   {
//     categories: [{ id: 1, title: "Cardio" }],
//     duration: 1560000,
//     highlight: null,
//     id: "2",
//     instructor: "Trainer/Instructor",
//     level: 4,
//     file_poster: "https://i.vimeocdn.com/video/851540766_1920x1080.jpg",
//     rating_stars: 4,
//     rating_votes: 1234,
//     title: "The Leg Crank",
//   },
//   {
//     categories: [{ id: 1, title: "Yoga" }],
//     duration: 1320000,
//     highlight: "staff-pick",
//     id: "3",
//     instructor: "Trainer/Instructor",
//     level: 4,
//     file_poster:
//       "https://go.burnalong.com/sites/default/files/videos/thumbnails/morning_khepera.jpg",
//     rating_stars: 4,
//     rating_votes: 1234,
//     title: "Morning Wake Up",
//   },
// ];

// export const MOCK_PROGRAMS = [
//   {
//     category: "Abs",
//     description:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
//     duration: 1200000,
//     equipment: [{ id: 1, title: "Sample Equipment" }],
//     id: "1",
//     instructor: "Trainer/Instructor",
//     level: 3,
//     file_image: "https://i.vimeocdn.com/video/693773775_1280x720.jpg",
//     rating: 3,
//     rating_count: 1234,
//     title: "Standing Abs 2 Standing Abs 2 Standing Abs 2",
//   },
//   {
//     category: "Cardio",
//     description:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
//     duration: 1560000,
//     equipment: [{ id: 1, title: "Sample Equipment" }],
//     id: "2",
//     instructor: "Trainer/Instructor",
//     level: 4,
//     file_image: "https://i.vimeocdn.com/video/851540766_1920x1080.jpg",
//     rating: 4,
//     rating_count: 1234,
//     title: "The Leg Crank",
//   },
//   {
//     category: "Yoga",
//     description:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
//     duration: 1320000,
//     equipment: [{ id: 1, title: "Sample Equipment" }],
//     id: "3",
//     instructor: "Trainer/Instructor",
//     level: 4,
//     file_image:
//       "https://go.burnalong.com/sites/default/files/videos/thumbnails/morning_khepera.jpg",
//     rating: 4,
//     rating_count: 1234,
//     title: "Morning Wake Up",
//   },
//   {
//     category: "Abs",
//     description:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
//     duration: 900000,
//     equipment: [{ id: 1, title: "Sample Equipment" }],
//     id: "4",
//     instructor: "Trainer/Instructor",
//     level: 3,
//     file_image:
//       "https://i.vimeocdn.com/video/586334106_1280x720.jpghttps://i.vimeocdn.com/video/586334106_1280x720.jpg",
//     rating: 3,
//     rating_count: 1234,
//     title: "Standing Abs",
//   },
//   {
//     category: "Educational",
//     description:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
//     duration: 300000,
//     equipment: [{ id: 1, title: "Sample Equipment" }],
//     id: "5",
//     instructor: "Trainer/Instructor",
//     level: 1,
//     file_image: "https://i.vimeocdn.com/video/677843380_1280x720.jpg",
//     rating: 1,
//     rating_count: 1234,
//     title:
//       "Fawn Heart Fit Nutrition Tip: ACV, The Perfect Way to Start the Morning",
//   },
//   {
//     category: "Bodyweight",
//     description:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
//     duration: 33,
//     equipment: [{ id: 1, title: "Sample Equipment" }],
//     id: "6",
//     instructor: "Trainer/Instructor",
//     level: 2,
//     file_image: "https://i.vimeocdn.com/video/927775902_1920x1080.jpg",
//     rating: 2,
//     rating_count: 1234,
//     title: "30 minute Bodyweight Strength Workout #3",
//   },
//   {
//     category: "Abs",
//     description:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
//     duration: 900000,
//     enrolled: {
//       percent: 80,
//       courses_completed: 4,
//       courses_total: 6,
//       days_remaining: 2,
//       days_total: 6,
//     },
//     equipment: [{ id: 1, title: "Sample Equipment" }],
//     id: "7",
//     instructor: "Trainer/Instructor",
//     level: 3,
//     file_image: "https://i.vimeocdn.com/video/748756125_1280x720.jpg",
//     rating: 3,
//     rating_count: 1234,
//     title: "Muffin Blast",
//   },
//   {
//     category: "Yoga",
//     description:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
//     duration: 2160000,
//     equipment: [{ id: 1, title: "Sample Equipment" }],
//     id: "8",
//     instructor: "Trainer/Instructor",
//     level: 1,
//     file_image: "https://i.vimeocdn.com/video/916477524_1920x1080.jpg",
//     rating: 1,
//     rating_count: 1234,
//     title: "Yoga for Anxiety",
//   },
//   {
//     category: "Strength",
//     description:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
//     duration: 300000,
//     equipment: [{ id: 1, title: "Sample Equipment" }],
//     id: "9",
//     instructor: "Trainer/Instructor",
//     level: 2,
//     file_image:
//       "https://go.burnalong.com/sites/default/files/videos/thumbnails/uflegcrusher.jpg",
//     rating: 2,
//     rating_count: 1234,
//     title: "Leg Crusher",
//   },
//   {
//     category: "Barre",
//     description:
//       "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
//     duration: 900000,
//     enrolled: {
//       percent: 25,
//       courses_completed: 0,
//       courses_total: 6,
//       days_remaining: 6,
//       days_total: 6,
//     },
//     equipment: [{ id: 1, title: "Sample Equipment" }],
//     id: "10",
//     instructor: "Trainer/Instructor",
//     level: 3,
//     file_image:
//       "https://go.burnalong.com/sites/default/files/videos/thumbnails/mcd_back_butt_and_core_challenge.jpg",
//     rating: 3,
//     rating_count: 1234,
//     title: "Arms, Back, Butt, and Core Challenge",
//   },
// ];

// export const MOCK_INSTRUCTORS = [
//   {
//     count: 781,
//     following: false,
//     id: "1",
//     image:
//       "https://go.burnalong.com/sites/default/files/styles/thumbnail/public/monte-sanders.jpg",
//     name: "Monte Sanders",
//   },
//   {
//     count: 399,
//     following: false,
//     id: "2",
//     image: "https://go.burnalong.com/uploads/users/profile-pictures/mCXVhl.jpg",
//     name: "Shay Hill",
//   },
//   {
//     count: 469,
//     following: false,
//     id: "3",
//     image: "https://go.burnalong.com/uploads/users/profile-pictures/yNQ9MZ.jpg",
//     name: "Heather Gidusko",
//   },
//   {
//     count: 159,
//     following: false,
//     id: "4",
//     image: "https://go.burnalong.com/uploads/users/profile-pictures/5AEUdT.png",
//     name: "JoanieFit LLC",
//   },
//   {
//     count: 69,
//     following: false,
//     id: "5",
//     image: "https://go.burnalong.com/uploads/users/profile-pictures/pavTht.jpg",
//     name: "Kelly Mase The Burpee Queen",
//   },
//   {
//     count: 36,
//     following: false,
//     id: "6",
//     image: null,
//     name: "Krystsina Matskevich",
//   },
// ];

// export const MOCK_PARTNERS = [
//   {
//     id: 1,
//     address: {
//       address_1: "120 Pheasant Run",
//       address_2: null,
//       city: "Newtown",
//       state: "PA",
//       zip: "18940",
//     },
//     description: null,
//     email: "erinlotta@gmail.com",
//     file_image: "https://i.vimeocdn.com/video/866681416_1920x1080.jpg",
//     file_logo:
//       "https://partners.burnalong.com/uploads/partners/logos/9RIlbn.png",
//     following: false,
//     follower_count: 440,
//     links: {
//       facebook: "https://www.facebook.com/https:www.facebook.comnewtownac",
//       instagram: "https://www.instagram.com/https:www.instagram.comnewtownac",
//       twitter: null,
//       website: "https://newtownathletic.com/",
//     },
//     name: "Newtown Athletic Club",
//     phone: null,
//   },
//   {
//     id: 2,
//     address: {
//       address_1: null,
//       address_2: null,
//       city: null,
//       state: "CA",
//       zip: null,
//     },
//     description:
//       "All of the workouts will consist of full body exercises with the direction of building strength in the core. Some different styles will focus on low body or upper body but will always hit all muscle groups during every workout. The variety of workouts will keep the body in a healthy shape and will never allow the muscles to get too comfortable so that they know what to expect. Nutrition will also be a factor in on some of the videos as that is a huge factor in staying healthy.",
//     email: "gymrat.cpt@gmail.com",
//     file_image: "https://i.vimeocdn.com/video/674829443_1280x1047.jpg",
//     file_logo:
//       "https://partners.burnalong.com/uploads/partners/logos/A6gGBc.png",
//     following: false,
//     follower_count: 64,
//     links: {
//       facebook: "https://www.facebook.com/https:www.facebook.comahealthycore",
//       instagram: "https://www.instagram.com/ahealthycore",
//       twitter: "https://www.twitter.com/ahealthycore1",
//       website: "https://www.ahealthycore.com/",
//     },
//     name: "a Healthy CORE",
//     phone: null,
//   },
//   {
//     id: 3,
//     address: {
//       address_1: "2160 NW Vine St",
//       address_2: null,
//       city: "Grants Pass",
//       state: "OR",
//       zip: "97526",
//     },
//     description:
//       "Are you longing to live better? Feel better? BE better? Club Northwest is a world-class fitness community that is passionate, determined, and supportive of your wellness. And it starts with you. Join The Club and strengthen your body and mind in the comfort of a place that feels like home. Our state-of-the-art facilities, free fitness classes, world class personal trainers, and multiple pools will keep you inspired to live your best life. Right here. Right now. Right in your own backyard.",
//     email: "amanda@clubnw.com",
//     file_image: "https://i.vimeocdn.com/video/876316487_1920x1080.jpg",
//     file_logo:
//       "https://partners.burnalong.com/uploads/partners/logos/UQmowY.png",
//     following: false,
//     follower_count: 39,
//     links: {
//       facebook: "https://www.facebook.com/clubnorthwest",
//       instagram: "https://www.instagram.com/clubnorthwest",
//       twitter: "https://www.twitter.com/ClubNorthwestGP",
//       website: "https://www.clubnw.com/",
//     },
//     name: "Club Northwest",
//     phone: null,
//   },
//   {
//     id: 4,
//     address: {
//       address_1: "300 Garrison Forest Rd",
//       address_2: null,
//       city: "Owings Mills",
//       state: "MD",
//       zip: "21117",
//     },
//     description:
//       "M.B.S Fitness Lab is a health and wellness company where Fitness aligns with Life. Life is full of responsibilities but none more important than the responsibility of taking care of yourself! We know you have demands on your time, so we provide easy to access, convenient fitness programs for all fitness levels. Whether you’re a professional athlete or couch potato, we have a program that will transform your body and life. And it doesn’t stop there. At M.B.S Fitness Lab, we believe in exercising your mind and spirit as well … focusing on all parts of our being as we strive for balance. Whether in-person or online, each workout will challenge you, change you and inspire you to want more. And there’s always more.",
//     email: "partnerId_511@burnalong.com",
//     file_image: "https://i.vimeocdn.com/video/586409473_1280x720.jpg",
//     file_logo: "https://go.burnalong.com/sites/default/files/MBS%20Logo.jpg",
//     following: false,
//     follower_count: 30,
//     links: {
//       facebook: null,
//       instagram: null,
//       twitter: null,
//       website: "http://mbsfitnesslab.com/",
//     },
//     name: "M.B.S Fitness Lab",
//     phone: null,
//   },
//   {
//     id: 5,
//     address: {
//       address_1: "2700 Stonewall St",
//       address_2: null,
//       city: "Knoxville",
//       state: "TN",
//       zip: "37921",
//     },
//     description:
//       "Ca-L'a Fitness TM, is a Fun workout program that targets your specific groups of muscles while burning calories, shedding inches, and toning all at the same time! You are having a great time becoming healthy, while conditioning your muscles! You feel inspired, uplifted, and encouraged to keep going throughout the workout, and to not give up. Are you ready for your goals to be accomplished? Let's start this journey together, with Ca-L'a Fitness TM!",
//     email: "calafitness@yahoo.com",
//     file_image: "https://i.vimeocdn.com/video/878442878_1920x1080.jpg",
//     file_logo:
//       "https://partners.burnalong.com/uploads/partners/logos/aRLCtT.jpg",
//     following: false,
//     follower_count: 24,
//     links: {
//       facebook:
//         "https://www.facebook.com/https:www.facebook.comCa-La-Fitness-897182210302418",
//       instagram: "https://www.instagram.com/calafitness",
//       twitter: null,
//       website: null,
//     },
//     name: "Ca-L'a Fitness TM",
//     phone: null,
//   },
// ];

export const STATUS = {
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
  DECLINED: "DECLINED",
};
export const MOCK_TEAM_CHALLENGE_MEMBERS = [
  {
    complete: 9,
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-307887-1591276698-503594.png",
    id: 1,
    isUser: true,
    name: "Michel M",
    status: STATUS.ACTIVE,
  },
  {
    complete: 9,
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-83-1547004995.png",
    id: 2,
    name: "Mike K",
    status: STATUS.ACTIVE,
  },
  {
    complete: 6,
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-82536-1560786345.png",
    id: 3,
    name: "broccoliplease",
    status: STATUS.ACTIVE,
  },
  {
    complete: 11,
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-97209-1569356814.jpeg",
    id: 4,
    name: "Sarthak M",
    status: STATUS.ACTIVE,
  },
  {
    complete: 12,
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-307716-1590695786.webp",
    id: 5,
    name: "Michele M",
    status: STATUS.ACTIVE,
  },
  {
    complete: 8,
    file_avatar: null,
    id: 6,
    name: "Ron B",
    status: STATUS.ACTIVE,
  },
  {
    complete: 0,
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-82539-1560787175.png",
    id: 7,
    name: "Alex H",
    status: STATUS.DECLINED,
  },
  {
    complete: 0,
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-336138-1596462294.jpeg",
    id: 8,
    name: "Georgie K",
    status: STATUS.PENDING,
  },
  {
    complete: 0,
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-59340-1548190263.jpeg",
    id: 9,
    name: "Sean W",
    status: STATUS.PENDING,
  },
  {
    complete: 3,
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-314379-1591720614.jpeg",
    id: 10,
    name: "Deo K",
    status: STATUS.ACTIVE,
  },
  {
    complete: 0,
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-72-1581004764.png",
    id: 11,
    name: "Daniel F",
    status: STATUS.ACTIVE,
  },
];

export const MOCK_FEED_DATA = [
  {
    body: {
      action: "Deo viewed",
      comments_count: 10,
      duration: 6000000,
      id: 2,
      instructor: "Shay Hill",
      likes_count: 2,
      poster: "https://i.vimeocdn.com/video/761614217_1280x720.jpg",
      rating: 3,
      rating_count: 1234,
      reaction: "Deo liked this",
      title: "This is my title",
    },
    id: 1,
    type: "course",
  },
  {
    body: {
      action: "Ron finished a class",
      comments_count: 10,
      emoji: "👊",
      id: 0,
      likes_count: 2,
      reaction: "Michel liked this",
    },
    id: 2,
    type: "milestone",
  },
  {
    body: {
      action: "John accomplished his goal. Great job!",
      comments_count: 10,
      emoji: "🎉",
      id: 0,
      likes_count: 31,
      reaction: null,
    },
    id: 3,
    type: "milestone",
  },
  {
    body: {
      action: "Michel liked this",
      comments_count: 10,
      id: 59,
      instructor: {
        count: 321,
        id: 59,
        image:
          "https://go.burnalong.com/uploads/users/profile-pictures/Qu1p8a.png",
        name: "Alex Haschen",
      },
      likes_count: 2,
      reaction: null,
    },
    id: 4,
    type: "user_action",
  },
  {
    body: {
      action: "Deo viewed",
      comments_count: 10,
      duration: 600000,
      id: 2,
      instructor: "Monte Sanders",
      likes_count: 2,
      poster: "https://i.vimeocdn.com/video/693773775_1280x720.jpg",
      rating: 4.1,
      rating_count: 1234,
      reaction: "Deo liked this",
      title: "This is my title",
    },
    id: 5,
    type: "course",
  },
  {
    body: {
      action: "Deo left a review",
      comments_count: 10,
      description:
        "Excepteur aute ipsum reprehenderit ullamco duis do est voluptate reprehenderit quis qui minim.",
      id: 10089,
      likes_count: 2,
      rating: 3.5,
      rating_count: 1234,
      reaction: "Ron liked this",
      title: "This is the class title",
    },
    id: 6,
    type: "review",
  },
  {
    body: {
      action: "Michel is following",
      comments_count: 10,
      id: 59,
      instructor: {
        count: 321,
        id: 59,
        image:
          "https://go.burnalong.com/sites/default/files/styles/thumbnail/public/monte-sanders.jpg",
        name: "Monte Sanders",
      },
      likes_count: 2,
      reaction: "A member liked this",
    },
    id: 7,
    type: "user_action",
  },
];

export const MOCK_PRIVATE_CHALLENGE = [
  {
    date_end: "2020-09-20T14:57:33.615771Z",
    date_start: "2020-08-01T14:57:33.615771Z",
    days_left: "28 days left",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.",
    file_image: "",
    id: 1,
    organizer: "Michel M.",
    target_description: "Highest number of classes",
    target_goal: 12,
    title: "Best Ever Super Duper Code Crunchiest Challenge Ever Ever Ever!",
    type: "PRIVATE",
    total_participants: 6,
    total_teams: 1,
  },
];

export const MOCK_TEAM_CHALLENGE = [
  {
    date_end: "2020-09-20T14:57:33.615771Z",
    date_start: "2020-08-01T14:57:33.615771Z",
    days_left: "17 days left",
    description: "",
    file_image:
      "https://go.burnalong.com/sites/default/files/pictures/GAC-307887-1597357824-a9d03.jpeg",
    id: 10,
    organizer: "broccoliplease",
    target_description: "Reach 30 classes",
    target_goal: 30,
    title: "Summer Sweat",
    type: "TEAM",
    total_participants: 18,
    total_teams: 2,
  },
];

export const MOCK_CONTACT_LIST = [
  {
    email: "mike@burnalong.com",
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-83-1547004995.png",
    id: 101,
    name: "Mike Kott",
  },
  {
    email: "sohee.oh@burnalong.com",
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-82536-1560786345.png",
    id: 102,
    name: "Sohee Oh",
  },
  {
    email: "ron.burr@burnalong.com",
    file_avatar: null,
    id: 103,
    name: "Ron Burr",
  },
  {
    email: "sarthak.mehta@burnalong.com",
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-97209-1569356814.jpeg",
    id: 104,
    name: "Sarthak Mehta",
  },
  {
    email: "michel.marrache@burnalong.com",
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-307887-1591276698-503594.png",
    id: 105,
    name: "Michel Marrache",
  },
  {
    email: "michele.mccarthy@burnalong.com",
    file_avatar: "https://go.burnalong.com/img/pictures/445403c1b8ece7a6.jpg",
    id: 106,
    name: "Michele McCarthy",
  },
  {
    email: "matthew.locker@burnalong.com",
    file_avatar: "https://go.burnalong.com/img/pictures/4f96f22cc397f54a.jpg",
    id: 107,
    name: "Matthew Locker",
  },
  {
    email: "deo.kalule@burnalong.com",
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-314379-1591720614.jpeg",
    id: 108,
    name: "Deo Kalule",
  },
  {
    email: "georgie.kourkounakis@burnalong.com",
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-336138-1596462294.jpeg",
    id: 109,
    name: "Georgie Kourkounakis",
  },
  {
    email: "jason.lopyan@burnalong.com",
    file_avatar: null,
    id: 110,
    name: "Jason Lopyan",
  },
];

export const MOCK_CONTACT_LIST_2 = [
  {
    email: "daniel@burnalong.com",
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-72-1581004764.png",
    id: 201,
    name: "Daniel Freedman",
  },
  {
    email: "sean.weisbord@burnalong.com",
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-59340-1548190263.jpeg",
    id: 202,
    name: "Sean Weisbord",
  },
  {
    email: "jodi.maggin@burnalong.com",
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-77097-1556041085.png",
    id: 203,
    name: "Jodi Maggin",
  },
  {
    email: "alex.haschen@burnalong.com",
    file_avatar:
      "https://go.burnalong.com/sites/default/files/pictures//L-82539-1560787175.png",
    id: 204,
    name: "Alex Haschen",
  },
];

export const MOCK_ACCORDION_DATA = [
  {
    id: 1,
    title: "My Organization",
    contacts: MOCK_CONTACT_LIST,
  },
  {
    id: 2,
    title: "Personal Contacts",
    contacts: MOCK_CONTACT_LIST_2,
  },
];

/* eslint-disable */
// const CONTACT_LISTS = [
//   {
//     id: INTEGER,
//     title: STRING,
//     contacts: [
//       {
//         id: INTEGER,
//         email: STRING,
//         file_avatar: STRING,
//         name: STRING,
//       },
//       {...},
//     ],
//   },
//   {...}
// ];

export const MOCK_TEST_USERS = [
  {
    name: "Alice",
    brand_user_ident: "test-alice",
    timezone: "America/Chicago",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWFsaWNlIiwiZXhwIjoyNTI0NjI5NjAwLCJpYXQiOjE3MjA0NjU3OTUsImlzX3Rlc3QiOnRydWV9.v3bFber-LW76QbfekIlitTXEbx25tSmSFZXJ2LaTyBQ",
  },
  {
    name: "Bob",
    brand_user_ident: "test-bob",
    timezone: "America/Los_Angeles",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWJvYiIsImV4cCI6MjUyNDYyOTYwMCwiaWF0IjoxNzIwNDY1Nzk1LCJpc190ZXN0Ijp0cnVlfQ.09EASS1khaOI9u6XgHVZhIHB5LkpnxYX_HORpdz1ryA",
  },
  {
    name: "Carol",
    brand_user_ident: "test-carol",
    timezone: "America/New_York",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWNhcm9sIiwiZXhwIjoyNTI0NjI5NjAwLCJpYXQiOjE3MjA0NjU3OTUsImlzX3Rlc3QiOnRydWV9._izlAkjGX4R5zflxxd0m8y12bIM0PeLw71yNW9bRjCY",
  },
  {
    name: "David",
    brand_user_ident: "test-david",
    timezone: "America/Denver",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWRhdmlkIiwiZXhwIjoyNTI0NjI5NjAwLCJpYXQiOjE3MjA0NjU3OTUsImlzX3Rlc3QiOnRydWV9.HFHEwGBziB8Qbn0dihF74TH9bjXK78G0qo-dq_rQpvM",
  },
  {
    name: "Eve",
    brand_user_ident: "test-eve",
    timezone: "Pacific/Honolulu",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWV2ZSIsImV4cCI6MjUyNDYyOTYwMCwiaWF0IjoxNzIwNDY1Nzk1LCJpc190ZXN0Ijp0cnVlfQ.0EFJUkXojuAMLOAHJd_LaMnO9KackPt1782wWIiM2G0",
  },
  {
    name: "Frank",
    brand_user_ident: "test-frank",
    timezone: "America/Chicago",
    jwt:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWZyYW5rIiwiZXhwIjoyNTI0NjI5NjAwLCJpYXQiOjE3MjA0NjU3OTUsImlzX3Rlc3QiOnRydWV9.ssF40sLGW7_Qt9K3iIdV06JXiVdFjU904-raEsZX1QE",
  },
];
