import { useCallback, useEffect, useMemo, useState } from "react";
import geApi, { getGeHeaders, getGeJwt } from "@utils/geApi";
import { Connection } from "ge_api";

export const useGeJwt = () => {
  const jwt = getGeJwt();
  const headers = getGeHeaders(jwt);

  return {
    jwt,
    headers,
  };
};

export const useGeApi = (
  endpoint: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any,
  method: "get" | "post" | "put" | "delete" = "get"
) => {
  // Memoize the dependencies to prevent unnecessary re-renders
  const stableParams = useMemo(() => params, [JSON.stringify(params)]);
  const stableEndpoint = useMemo(() => endpoint, [endpoint]);
  const stableMethod = useMemo(() => method, [method]);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | boolean>(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const response = await geApi(stableEndpoint)[stableMethod](stableParams);
      setData(response.data);
    } catch (err) {
      const errorInstance = err instanceof Error ? err : new Error(String(err));
      setError(errorInstance);
    } finally {
      setLoading(false);
    }
  }, [stableEndpoint, stableMethod, stableParams]);

  // Initial and dependency-based fetch
  useEffect(() => {
    let isMounted = true; // Track if component is still mounted
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await geApi(stableEndpoint)[stableMethod](stableParams);
        if (isMounted) {
          setData(response.data);
        }
      } catch (err) {
        if (isMounted) {
          const errorInstance = err instanceof Error ? err : new Error(String(err));
          setError(errorInstance);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [stableEndpoint, stableMethod, stableParams]);

  // Refetch function that calls fetchData
  const refetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error, refetch };
};

export const useGeEndpoint = (endpoint, params) => {
  const api = useGeApi(endpoint, params);
  const { results: data = [], total, total_pages, page, per_page } = api.data || {};
  return {
    ...api,
    data,
    total,
    total_pages,
    page,
    per_page,
  };
};

export const useGeEndpointInfinite = (endpoint, initialParams) => {
  const [params, setParams] = useState(initialParams);
  const [currentPage, setCurrentPage] = useState(1);
  const [accumulatedData, setAccumulatedData] = useState([]);

  const { data, loading, error, total, total_pages, page, per_page } = useGeEndpoint(endpoint, {
    ...params,
    page: currentPage,
  });

  useEffect(() => {
    if (data.length > 0) {
      setAccumulatedData((prevData) => [...prevData, ...data]);
    }
  }, [data]);

  const loadMore = useCallback(() => {
    console.log("loadMore", currentPage, total_pages);
    if (currentPage < total_pages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, total_pages]);

  const hasMore = currentPage < total_pages;

  return {
    data: accumulatedData,
    loading,
    error,
    total,
    total_pages,
    page,
    per_page,
    loadMore,
    hasMore,
  };
};

export const useGeStreaks = (params?: object) => {
  const api = useGeApi("users/me", params);
  const streaks = api.data?.progress?.goals?.streak || [];
  const findStreakById = (id) => streaks?.find((streak) => streak.goal_id === id);
  // TODO: move hardcode ids out of codebase
  // get from brand config instead
  const { featured_streak_ids } = {
    featured_streak_ids: {
      day: 1,
      week: 2,
      month: 3,
    },
  };

  const featured = Object.entries(featured_streak_ids);

  return {
    ...api,
    streaks,
    findStreakById,
    featured,
  };
};

export const useGePoints = (params?: object) => {
  const { data: usersMeData, loading } = useGeApi("users/me", params);
  const points = usersMeData?.stats?.BrandStat_1 || {};
};

type I = Connection["service_ident"];
export const useGeDataConnections = () => {
  const api = useGeEndpoint("data/connections", {});
  const { findConnection, byIdent } = useMethods();

  return {
    ...api,
    findConnection,
    apple: findConnection("apple"),
    google: findConnection("google"),
    fitbit: findConnection("fitbit"),
    garmin: findConnection("garmin"),
  };

  function useMethods() {
    return {
      findConnection: (ident: I) => {
        const connection = api.data.find(byIdent(ident));
        if (!connection) {
          console.error(`Connection with ident ${ident} not found`);
        }
        return (connection as unknown) as Connection;
      },
      byIdent: (ident: I) => (c: Connection) => c.service_ident === ident,
    };
  }
};
