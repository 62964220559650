import axios from "axios";
import { useGeJwt } from "../_hooks/useGeApi";
import secureLocalStorage from "react-secure-storage";
import { MOCK_TEST_USERS } from "../_mockData";

export const getGeJwt = () => {
  // const name = "Bob";
  // const name = "Carol";
  // const name = "David";
  // const name = "Eve";
  const name = "Alice";
  const userByName = (u) => u.name === name;
  const tester = MOCK_TEST_USERS.find(userByName);
  // return tester ? tester.jwt : ""; // !DEV ONLY: COMMENT FOR PROD

  const tokenData = secureLocalStorage.getItem("token");
  return tokenData ? JSON.parse(tokenData).access : null;
};

export const getGeHeaders = (jwt = getGeJwt()) => {
  const language = navigator.language;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    "x-ge-brand": "ba",
    "x-ge-client": "ba_react_1_2_3",
    "x-ge-language": language,
    "x-ge-timezone": timezone,
    "x-ge-jwt": jwt,
  };
};

export default function geApi(endpoint) {
  const api = import.meta.env.VITE_APP_GE_API_URL;
  const url = `${api}/ge/${endpoint}`;
  const { headers } = useGeJwt();

  return {
    get: async (params) => {
      return await axios.get(url, { params, headers });
    },
    post: async (params) => {
      try {
        return await axios.post(url, params, { headers });
      } catch (error) {
        console.log("error", error);
        console.log(url, params, headers);
      }
    },
    put: async (params) => {
      return await axios.put(url, params, { headers });
    },
    delete: async (params) => {
      return await axios.delete(url, { params, headers });
    },
  };
}

export async function deleteConnectionById(id) {
  const api = geApi(`data/connections/${id}`);
  await api.delete();
}

export async function syncConnectionById(id) {
  const api = geApi(`data/connections/${id}/sync`);
  await api.post();
}
