import React, { memo, ReactNode } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Heading1, Heading4, Heading6, Link, Col, Row, Pagination, Button, PageLoader } from ".";
import { Dropdown } from "../_components/Dropdown";
import Breadcrumbs from "../_components/Breadcrumbs";
import { noMargin, noPadding } from "../_theme/general";
import BaseSkeleton from "./Skeletons/BaseSkeleton";

type PageProps = {
  crumbsLifted?: boolean;
  crumbs?: { title: string; link: string }[];
  crumbsColor?: string;
  title?: string;
  docTitle?: string;
  subLinkURL?: string;
  subLinkTitle?: string;
  dropdown?: Dropdown;
  fluid?: boolean;
  loading?: boolean;
  alternateTitle?: string;
  children?: ReactNode;
};

const Page = ({
  crumbsLifted,
  crumbs,
  crumbsColor,
  title,
  docTitle,
  subLinkURL,
  subLinkTitle,
  dropdown,
  fluid = false,
  loading = false,
  alternateTitle,
  children,
  ...props
}: PageProps) => {
  const conatinerId = `${docTitle || title || "container"}-page`;
  return loading ? (
    <PageLoader height="100vh" />
  ) : (
    <>
      <Helmet>
        <title>{docTitle || title}</title>
      </Helmet>
      <Container $fluid={fluid} id={conatinerId}>
        {crumbs && (
          <BreadcrumbsRow $above={crumbsLifted} $fluid={fluid}>
            <Breadcrumbs crumbs={crumbs} color={crumbsColor} />
          </BreadcrumbsRow>
        )}
        {title ||
        alternateTitle ||
        subLinkURL ||
        props.renderDropdown ||
        dropdown?.options?.length > 0 ? (
          <Row $align="center">
            {title && (
              <Col $align={"flex-start"} $px={0}>
                <Heading1 $mb={4} weight={900}>
                  {title}
                </Heading1>
              </Col>
            )}
            {alternateTitle && (
              <Col $align={"flex-start"}>
                <Heading4 $mb={4} weight={700}>
                  {alternateTitle}
                </Heading4>
              </Col>
            )}
            {subLinkURL || dropdown?.options?.length > 0 || props.button ? (
              <Col md="auto" $px={0} $align="flex-end" $mb={2} xs={12}>
                {subLinkURL ? (
                  <Link
                    to={subLinkURL}
                    {...props}
                    aria-label={props.title ? `See all ${title}` : null}
                  >
                    {subLinkTitle || "View More"}
                  </Link>
                ) : dropdown?.options?.length > 0 ? (
                  <Dropdown
                    isSearchable={false}
                    onChange={(e) => dropdown.onChange(e)}
                    options={dropdown.options || []}
                    value={dropdown.value}
                    ariaLabel="test aria label"
                    minWidth={dropdown.minWidth || "15em"}
                  />
                ) : props?.button ? (
                  <Button
                    onClick={props.button.onClick}
                    id={`${props.button.text.replace(/[^0-9a-zA-Z]/g, "")}-button`}
                  >
                    {props.button.text}
                  </Button>
                ) : null}
              </Col>
            ) : null}
          </Row>
        ) : null}

        {props.titleBar ? props.titleBar : null}
        {props.subTitle ? <Heading4>{props.subTitle}</Heading4> : null}
        {children}
        {props.pagination ? (
          <Pagination
            onPageChange={(e) => {
              props.onPageChange(e);
              if (props.paginationScroll)
                document.getElementById(conatinerId).scrollIntoView({
                  behavior: "smooth",
                });
            }}
            pageCount={props.pageCount}
            marginPagesDisplayed={props.marginPagesDisplayed}
            pageRangeDisplayed={props.pageRangeDisplayed}
            activePage={props.activePage}
          />
        ) : null}
      </Container>
    </>
  );
};

const BreadcrumbsRow = styled(Row)`
  position: ${(props) => props.$above && "absolute"};
  padding-left: ${({ $fluid }) => ($fluid ? "2rem" : 0)};
  padding-right: ${({ $fluid }) => ($fluid ? "2rem" : 0)};
  padding-top: ${({ $fluid }) => ($fluid ? "1.5rem" : 0)};
  margin: 0;
  z-index: ${(props) => props.$above && 10};
`;

const Container = styled.div`
  margin: 0;
  padding: ${({ $fluid }) => ($fluid ? 0 : "1.5rem 2rem")};
  @media (max-width: 1200px) {
    padding: ${({ $fluid }) => ($fluid ? 0 : "0.5rem 1rem")};
  }
  &#Progress-page {
    background: #fff !important;
  }
`;

const PageCardWrapper = styled(Col)`
  background-color: ${({ $bg, theme }) => $bg || theme.palette.neutralWhite};
  border-radius: 24px;
  border: ${({ $border, theme }) => $border && "1px solid " + theme.palette.neutralDarkerGrey};
  ${noMargin};
  ${noPadding};
`;

type PageCardProps = {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  loading?: boolean;
  showTitleLoading?: boolean;
  ellipsis?: boolean;
  amt?: number;
};

export const PageCard = ({
  title,
  subtitle,
  children,
  loading,
  showTitleLoading,
  ellipsis,
  amt,
  ...rest
}: PageCardProps) => {
  return (
    <PageCardWrapper $px={8} $py={8} $mb={20} {...rest}>
      {showTitleLoading && loading ? (
        <BaseSkeleton height="2.5rem" width="20rem" $mb={10} />
      ) : (
        <>
          {title && (
            <Heading4
              weight="700"
              $mb={subtitle ? 2 : 10}
              $ellipsis={ellipsis ? true : false}
              $amt={amt}
            >
              {title}
            </Heading4>
          )}
          {subtitle && (
            <Heading6 weight="700" $mb={10}>
              {subtitle}
            </Heading6>
          )}
        </>
      )}

      {children}
    </PageCardWrapper>
  );
};

export const Separator = styled.div`
  width: ${({ $width }) => $width};
  height: 0px;
  color: ${({ theme }) => theme.palette.primaryBlue};
  border: 1px solid ${({ theme }) => theme.palette.primaryBlue} !important;
  opacity: 0.1;
  margin-top: ${({ $my, $mt }) =>
    ($my && `${$my * 0.25}rem !important`) || ($mt && `${$my * 0.25}rem !important`) || "2rem"};
  margin-bottom: ${({ $my, $mb }) =>
    ($my && `${$my * 0.25}rem !important`) || ($mb && `${$mb * 0.25}rem !important`) || "2rem"};
  margin-left: ${({ centered }) => centered && "auto !important"};
  margin-right: ${({ centered }) => centered && "auto !important"};
  ${noMargin}
`;

export default memo(Page);
