// * GE - Gameification Engine Styles

import styled from "styled-components";
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Paragraph1,
  Paragraph2,
  Paragraph3,
  Row,
  Col,
} from "../_components";
import { StyledCard } from "@components/_cards/CardBase";
import { Card } from "react-bootstrap";
import Button from "@components/Buttons/Button";
import {
  buttonMetrics,
  cardMetrics,
  extraExtraExtraLargeBorderRadius,
  extraLargeFontSize,
  largeFontSize,
  mediumFontSize,
  smallerFontSize,
  smallFontSize,
} from "@theme/numbers";
import theme from "@theme";
import { baseGray001 } from "@theme/colors";

const { primaryBlue, neutralWhite: white001 } = theme.palette;

const px = {
  p1: "10px",
  p2: "20px",
  p3: "30px",
  buttonRadius: `${extraExtraExtraLargeBorderRadius}px`,
  cardRadius: "20px",
};

export const font = {
  family: "'DM Sans', sans-serif",
  size: {
    // headings
    h1: 12 + extraLargeFontSize, // 52px
    h2: 4 + extraLargeFontSize, // 34px
    h3: 3 + largeFontSize, // 28px
    h4: 2 + mediumFontSize, // 22px
    h5: mediumFontSize, // 20px
    h6: 1 + smallFontSize, // 17px

    // paragraphs
    p1: smallFontSize, // 16px
    p2: 1 + smallerFontSize, // 13px
    p3: smallerFontSize, // 12px
    //
    xl: extraLargeFontSize,
    lg: largeFontSize,
    md: mediumFontSize,
    sm: smallFontSize,
    xs: smallerFontSize,
  },
};

const GeGUI = {
  H1: styled(Heading1)`
    color: ${primaryBlue};
    font-family: ${font.family};
    font-size: ${font.size.h1}px;
    font-weight: 700;
  `,

  H2: styled(Heading2)`
    color: ${primaryBlue};
    font-family: ${font.family};
    font-size: ${font.size.h2}px;
    font-size: 28px;
    font-weight: 900;
  `,

  H3: styled(Heading3)`
    color: ${primaryBlue};
    font-family: ${font.family};
    font-size: ${font.size.h3}px;
    font-weight: 900;
  `,

  H4: styled(Heading4)`
    color: ${primaryBlue};
    font-family: ${font.family};
    font-size: ${font.size.h4}px;
    font-weight: 900;
  `,

  H5: styled(Heading5)`
    color: ${primaryBlue};
    font-family: ${font.family};
    font-size: ${font.size.h5}px;
    font-weight: 700;
    padding-left: 15px;
    padding-bottom: 5px;
  `,

  H6: styled(Heading6)`
    color: ${primaryBlue};
    font-family: ${font.family};
    font-size: ${font.size.h6}px;
    line-height: 1.3;
    margin-bottom: 10px;
  `,

  P1: styled(Paragraph1)`
    font-family: ${font.family};
    font-size: ${font.size.p1}px;
    margin-bottom: ${px.p1};
    font-size: ${font.size.p1}px;
  `,

  P2: styled(Paragraph2)`
    font-family: ${font.family};
    font-size: ${font.size.p2}px;
    line-height: 1.4;
    margin-bottom: ${px.p1};
    font-size: ${font.size.p2}px;
  `,

  P3: styled(Paragraph3)`
    font-family: ${font.family};
    font-size: ${font.size.p3}px;
    line-height: 1.33;
    color: ${baseGray001};
  `,

  HeroView: styled.div`
    background-color: ${white001};
    padding: ${px.p1};
    margin-bottom: ${px.p2};
  `,

  HeaderView: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    padding-right: 20px;
  `,

  Row: styled(Row)``,
  Col: styled(Col)``,

  Button: {
    Pressable: styled(Button)`
      background-color: ${primaryBlue};
      padding-left: ${buttonMetrics.paddingLeft}px;
      padding-right: ${buttonMetrics.paddingRight}px;
      border-radius: ${buttonMetrics.borderRadiusLarge}px;
      margin: 5px 0;
      width: 100%;
      min-height: ${buttonMetrics.heightNormal}px;
      line-height: ${buttonMetrics.lineHeight}px;
      font-family: ${font.family};
    `,

    Link: styled.a`
      background-color: ${primaryBlue};
      color: ${white001} !important;
      padding-left: ${buttonMetrics.paddingLeft}px;
      padding-right: ${buttonMetrics.paddingRight}px;
      border-radius: ${buttonMetrics.borderRadiusLarge}px;
      margin: 5px 0;
      width: 100%;
      min-height: ${buttonMetrics.heightNormal}px;
      line-height: ${buttonMetrics.heightNormal}px;
      font-size: ${buttonMetrics.fontSize}px;
      font-family: ${font.family};
      text-decoration: none !important;
    `,

    Pill: styled(Button)`
      margin: 0px;
      height: auto;
      min-height: unset;
      padding: 3px 16px;
      font-size: 13px;
      line-height: 18px;
      font-weight: bold;
      cursor: pointer;
      font-family: ${font.family};
    `,

    PrimaryText: styled.span`
      color: ${white001};
      font-family: ${font.family};
      font-size: ${font.size.sm}px;
      font-weight: 700;
      text-align: center;
      font-family: ${font.family};
    `,

    Secondary: styled(Button)`
      background-color: ${white001};
      border-color: ${primaryBlue};
      color: ${primaryBlue};
      border-radius: ${buttonMetrics.borderRadiusLarge}px;
      border-width: ${buttonMetrics.borderWidth}px;
      line-height: ${buttonMetrics.lineHeight}px;
      margin: 5px 0;
      min-height: ${buttonMetrics.heightNormal}px;
      padding-left: ${buttonMetrics.paddingLeft}px;
      padding-right: ${buttonMetrics.paddingRight}px;
      width: 100%;
      font-family: ${font.family};
      line-height: unset;
      cursor: pointer;
      font-weight: 700;
      color: ${primaryBlue};
      &:hover {
        background: ${theme.palette[`hoverOutlineLight`]};
      }
      &:active {
        background: ${theme.palette[`hoverOutlineDark`]};
      }
    `,

    Tertiary: styled(Heading6)`
      width: fit-content;
      color: ${primaryBlue};
      font-family: ${font.family};
      font-size: ${font.size.h6}px;
      line-height: 1.3;
      cursor: pointer;
      font-weight: 700;
      margin: 10px auto;
      text-decoration: none;
    `,

    SecondaryText: styled.span`
      color: ${primaryBlue};
      font-family: ${font.family};
      font-size: ${font.size.sm}px;
      font-weight: 400;
      text-align: center;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        color: rgb(76, 101, 127);
      }
    `,

    Circle: styled(Button)`
      width: 35px;
      height: 35px;
      margin: 0;
      padding: 0px !important;
      background: ${primaryBlue};
      font-family: ${font.family};
      cursor: pointer;
      display: flex !important;
      align-items: center;
      justify-content: center;
    `,
  },

  Card: {
    View: styled.div`
      background: ${white001};
      border-radius: ${cardMetrics.borderRadius}px;
      /* margin: 0 2.5% 15px; */
      padding: ${cardMetrics.padding}px;
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
      flex: 1;
    `,

    Badge: styled(StyledCard)`
      padding: ${cardMetrics.padding}px;
      text-align: center;
      flex: 1;
      justify-content: center;
      align-items: center;
      margin: 0;
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
      &:hover {
        box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.2);
      }
    `,

    Title: styled(Card.Title)`
      font-family: ${font.family};
    `,
  },
};

export default GeGUI;
